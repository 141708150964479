import React from 'react'
import { MdOutlineEmail } from 'react-icons/md'
import { AiOutlineBehanceSquare } from 'react-icons/ai'
const Contact = () => {
  return (
    <div className='flex flex-col items-center bg-black backdrop-blur-2xl bg-opacity-30'>
      <h1 className='font-pollyregular text-white text-4xl text-center mt-6 mb-6'> Contact Info </h1>
      <div className='flex items-center gap-x-3  mb-2'>
          <MdOutlineEmail className='text-white' size='2em'/>
          <a href='mailto:skyexgfx@gmail.com' className='font-pollyregular text-white text-xl'>skyexgfx@gmail.com</a>
      </div>
      <div className='flex items-center gap-x-3  mb-5'>
          <AiOutlineBehanceSquare className='text-white' size='2em'/>
          <a href='https://www.behance.net/skyexgfx5dba' target='_blank' className='font-pollyregular text-white text-xl'>More designs here!</a>
      </div>
    </div>
  )
}

export default Contact