import React from 'react'
import sxlogo from '../images/sx-sinfondoblanco.png'


const Header = () => {
  return (
    <div className='z-50 backdrop-blur-xl flex justify-between items-center w-full h-20 px-4 bg-black bg-opacity-50 md:fixed'>
        <div className='flex items-center'> 
          <img className='w-24 h-24 p-3 gap-2 select-none' src={sxlogo} />
          <h1 className='font-pollyregular font-black text-white text-xl select-none'>SkyeX</h1>
        </div>
        <button className='md:hidden font-pollyregular bg-white rounded-3xl text-black p-2'>Download CV</button>

        <ul className='font-thin text-white font-pollyregular gap-8 select-none hidden md:flex'>
          <a className='cursor-pointer hover:text-gray-400' href='#About'>About</a>
          <a className='cursor-pointer hover:text-gray-400' href='#Skills'>Skills</a>
          <a className='cursor-pointer hover:text-gray-400' href='#Projects'>Projects</a>
        </ul>
        
        <a href='#Contact' className=' text-black font-pollyregular px-8 py-2 bg-white rounded-3xl hidden md:flex md:transform hover:scale-110 md:transition md:duration-200
        active:bg-gray-400 active:text-white'>Contact!</a>
    </div>
  
  )
}

export default Header