import React, { useEffect, useState } from 'react'
import Card from './Card'

import { urlFor, client } from '../client'
import notfound from '../images/notfound.png'


const ProjectSet = () => {

  const [cards, setCards] = useState([])
  

  useEffect(() => {
    
    console.log('useEffect called')

    const query = '*[_type == "card" ]'
  
    client.fetch(query)
      .then((data) => {
        data = data.sort((a, b) => a.title > b.title ? 1 : -1); 
        setCards(data);
      })
      .catch((error) => console.log('ERROR: ', error))
     
  }, [])
  
  return (
      <div className='flex flex-col items-center justify-between font-pollyregular'>
        <h1 className=' text-white mt-5 md:mt-[10rem]  mb-7 text-4xl md:text-6xl font-extrabold bg-gray-600 bg-opacity-25 md:bg-black md:bg-opacity-25 backdrop-blur-md px-3 md:px-5 pt-2 md:pt-5 pb-3 md:pb-6 rounded-xl md:rounded-3xl shadow-[3px_10px_20px_0px_rgba(0,0,0,0.5)]'>Projects</h1>
        <div className='flex flex-row gap-x-5 w-full pb-[2rem] overflow-x-scroll scrollbar-hide md:scrollbar-default md:scrollbar-thin md:scrollbar-thumb-gray-900'>
          {cards.map((card) => (
            <Card
              key={card._id}
              title={card.title}
              subtitle={card.subtitle}
              desc={card.desc}
              image={urlFor(card.thumbnailimage).url()}
              photoview={card.projectimage ? urlFor(card.projectimage).url() : notfound}
            />
          ))}
        </div>
      </div>
    ) 
}

const Projects = () => {
  return (
    <div className='font-pollyregular text-white flex flex-col mb-8 mx-auto max-w-5xl' >
      <ProjectSet />
    </div>
  )
}

export default Projects