import React from 'react'
import { Html5OriginalIcon,
         PhotoshopPlainIcon,
         AftereffectsPlainIcon,
         IllustratorPlainIcon,
         Css3OriginalIcon,
         ReactOriginalIcon,
         NextjsOriginalIcon,
         JavascriptOriginalIcon,
         FigmaOriginalIcon,
         XdPlainIcon} from "react-devicons";
import { motion } from 'framer-motion'

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.1
    }
  }
};

const item = {
  hidden: {opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
}

const UpperSkillIcon = ({icon}) => {
  return(
    <motion.div
    initial={{ scale: 0 }}
    whileInView={{ rotate: 360, scale: 1 }}
    transition={{
      type: "spring",
      stiffness: 260,
      damping: 20 
    }}>
      {icon}
    </motion.div>
  )
}

const SkillIcon = ({icon}) => {
  return(
    <motion.div variants={item} >
      {icon}
    </motion.div>
  )
}

const SkillSet = () => {
  return(
    <div className='flex items-center justify-between flex-col mx-auto gap-5'>
      <h1 className='md:mb-[5rem] text-4xl md:text-6xl font-extrabold text-shadow-xl'>Skills</h1>
      <div className='flex lg:gap-20 md:gap-10 gap-5 md:mb-[5rem]  lg:scale-150 md:scale-125 scale-100'>          
        <UpperSkillIcon icon={<PhotoshopPlainIcon className='rounded-3xl object-cover shadow-[10px_5px_15px_0px_rgba(0,0,0,0.3)]' size='5em'/>} />               
        <UpperSkillIcon icon={<IllustratorPlainIcon className='rounded-3xl object-cover shadow-[10px_5px_15px_0px_rgba(0,0,0,0.3)]' size='5em'/>} /> 
      </div>
      <motion.div 
      variants={container}
      initial="hidden"
      whileInView="visible"
      className='lg:gap-20 md:gap-10 gap-5 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-8'>
        <SkillIcon icon={<AftereffectsPlainIcon className='bg-gray-500 rounded-3xl object-cover' size='5em' name='After Effects'/>} />
        <SkillIcon icon={<Html5OriginalIcon size='5em' name='HTML'/>} />
        <SkillIcon icon={<Css3OriginalIcon size='5em' name='CSS'/>} />
        <SkillIcon icon={<ReactOriginalIcon className='rounded-full' size='5em' name='ReactJS'/>} />
        <SkillIcon icon={<NextjsOriginalIcon className='bg-white p-1 rounded-full' size='5em' name='NextJS'/>} />
        <SkillIcon icon={<JavascriptOriginalIcon className='rounded-3xl object-cover' size='5em' name='JS'/>} />
        <SkillIcon icon={<FigmaOriginalIcon size='5em' name='Figma'/>} />
        <SkillIcon icon={<XdPlainIcon className='rounded-3xl object-cover' size='5em' name='XD'/>} />
      </motion.div>
    </div>
  )
}

const Skills = () => {
  return (
    <div className='font-pollyregular text-white  h-screen overflow-hidden flex items-center -mt-10 md:mt-0'>
      <SkillSet />
    </div>
  )
}

export default Skills