import React, { useState } from 'react'
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { motion } from 'framer-motion'

const Card = ({title, subtitle, desc, image, photoview}) => {
  
  const focused = 'absolute inset-0 h-full w-full rounded-xl bg-black/80 px-12 text-center text-slate-200'
  const unfocused = 'hidden absolute inset-0 h-full w-full rounded-xl bg-black/80 px-12 text-center text-slate-200'
  
  const [focus, setFocus] = useState(false);

  const handleClick = () => {
    setFocus(!focus);
  }
  
  return (
    <PhotoProvider maskOpacity={0.8}>
      <div className='flex items-center justify-center snap-center'>
        <div className='group h-96 w-80 [perspective:1000px]'>
          <div onClick={handleClick} className='relative h-full w-full rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d]'>
            <div className='absolute inset-0'>
              <div className='h-full w-full  relative text-center text-white'>
                <h1 className='w-full bottom-0 absolute p-5 rounded-xl	bg-gray-900/10 backdrop-blur-lg'>Click me!</h1>
                <img className='h-full w-full rounded-xl object-cover shadow-xl shadow-black/40' src={image} alt='' />
              </div>
            </div>
            <div className={focus ? focused : unfocused}>
              <div className='flex min-h-full flex-col items-center justify-center'>
                <h1 className='text-3xl font-bold'>{title}</h1>
                <p className='text-lg'>{subtitle}</p>
                <p className='text-base'>{desc}</p>
                <PhotoView src={photoview}>
                  <button primary className='mt-2 rounded-md bg-purple-500 py-1 px-2 text-sm hover:bg-blue-900'>View Project</button>
                </PhotoView>
              </div>
            </div>
          </div>
        </div>  
      </div>
    </PhotoProvider>
    
  )
}

export default Card