import './App.css';
import Header from './components/Header';
import About from './components/About';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Contact from './components/Contact';


const App1 = () => {
  return (
    
    <div className=' select-none overflow-scroll md:h-screen snap-y snap-mandatory scrollbar-thin scrollbar-thumb-gray-900'>

        <div className='md:bg-[url("images/bg.png")] '>
          <section id='About' className='snap-start'>
            <Header />
            <About />
          </section>
        </div>
        <div className='md:bg-[url("images/bg2.png")]'>
          <section id='Skills' className='snap-start'>
            <Skills />
          </section>
        </div>
        <div className='md:bg-[url("images/bg3.png")]'>
          <section id='Projects' className='snap-start'>
            <Projects />
          </section>
          <section id='Contact' className='snap-start'>
            <Contact />
          </section>
        </div>
        
    </div>

  );
}

const App2 = () => {
  return(
    <div className='bg-[url("images/bgmobile.png")] bg-no-repeat bg-left select-none'>
      <Header />
      <About />
      <Skills />
      <Projects />
      <Contact />
    </div>
  )
}

export {App1, App2};
