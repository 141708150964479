import React from 'react'
import fotome from '../images/fotohome.png'
import {HiChevronDoubleDown} from 'react-icons/hi'
import { motion } from 'framer-motion'

const MobileIntro = () => {
  return (
    <div className='flex flex-col items-center text-white'>
      <img className='mt-8 z-10 max-w-[8rem] rounded-full shadow-[0px_10px_7px_0px_rgba(0,0,0,0.4)]' src={fotome}></img>
      <div className='mx-2 max-w-sm bg-black bg-opacity-30 backdrop-blur-3xl  -translate-y-3 rounded-2xl p-3 shadow-[3px_10px_20px_0px_rgba(0,0,0,0.3)]'>
        <h1 className='text-2xl font-extrabold font-pollyregular select-none'> Hi, I am Jose Manuel,</h1>
        <p className='text-lg font-bold font-pollythin select-none'>
          I am 21 year old second year student of Industrial Design and Product Development Engineering.
          My interest includes social media graphic design as a freelance, graphic motion design, illustration and currently
          I am focused it in front-end development with several technologies such as HTML, CSS, JavaScript, ReactJS and NextJS.
        </p>
      </div>         
    </div>
  );
}

const NormalIntro = () => {
  return (
    <div className='flex flex-col items-center text-white'>
      <img className='mt-36 z-10 max-w-[15rem] rounded-full shadow-[0px_10px_7px_0px_rgba(0,0,0,0.4)]' src={fotome}></img>
      <div className='mx-2 max-w-xl bg-black bg-opacity-30 backdrop-blur-3xl  -translate-y-3 rounded-2xl p-3 shadow-[3px_10px_20px_0px_rgba(0,0,0,0.3)]'>
        <h1 className='text-2xl font-extrabold font-pollyregular select-none'> Hi, I am Jose Manuel,</h1>
        <p className='text-lg font-bold font-pollythin select-none'>
          I am 21 year old second year student of Industrial Design and Product Development Engineering.
          My interest includes social media graphic design as a freelance, graphic motion design, illustration and currently
          I am focused it in front-end development with several technologies such as HTML, CSS, JavaScript, ReactJS and NextJS.
        </p>
      </div>         
        <button className='mt-4 font-pollyregular bg-white rounded-3xl text-black p-2'>Download CV</button>
    </div>
  );
}


const About = () => {
  return (
    <div className='items-center'>
      <div className='flex-col mx-auto flex md:hidden'>
        <MobileIntro />
      </div>
      <div className='h-screen flex-col gap-[5rem] mx-auto max-w-4xl hidden md:flex'>
        <NormalIntro />
        <motion.div
        animate={{
          y: [-20, 0, -20],
        }}
        transition={{ ease: "easeOut", duration: 2.5, repeat: Infinity }}>
          <HiChevronDoubleDown style={{color: 'white', marginInline: 'auto'}} size='5em' />              
        </motion.div>
      </div>
    </div>
  )
}

export default About